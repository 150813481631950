<template>
  <v-container class="my-4 py-6">
    <h2>Descuentos de clientes:</h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        class="px-4 py-4"
        max-width="800"
      >
        <p class="text-primary">
          {{ actionText }} Descuento:
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation 
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="descuento.nombre_descuento"
                label="Nombre del descuento"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.number="descuento.porcentaje_descuento"
                type="number"
                label="Porcentaje de descuento"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-select
                v-model="descuento.clientes"
                :rules="[rules.required]"
                :items="customerWithoutDiscount"
                :item-text="item => `${fullName(item)}`"
                item-value="id"
                label="Clientes sin descuento:"
                hint="Clientes que tendrán descuento"
                no-data-text="No hay datos disponibles"
                multiple
                persistent-hint
              ></v-select>
            </v-col>
            <v-col
              v-if="editing"
              cols="12"
            >
              <v-select
                v-model="descuento.clientesConDescuento"
                :rules="[rules.required]"
                :items="discountCustomers"
                :item-text="item => `${fullName(item)}`"
                item-value="id"
                label="Clientes con descuento:"
                hint="Clientes que tienen asignado este descuento. Al desmarcar un cliente de esta lista se le quitará el descuento"
                no-data-text="No hay datos disponibles"
                multiple
                persistent-hint
                @change="item => onChange(item)"
              ></v-select>
            </v-col>
            
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="actionButton"
              >
                {{ actionText }}
              </v-btn>
              <v-btn
                color="primary"
                @click="reset"
              >
                limpiar campos 
              </v-btn>
            </v-card-actions>
          </v-row> 
        </v-form>
      </v-card>
    </v-row>

    <v-row
      class="my-4"
      justify="center"
    >
      <v-card
        v-for="descuento in descuentos"
        :key="descuento.id"
        max-width="300"
        class="mx-4 my-4"
      >
        <v-card-text>
          <p class="display-1 text--primary">
            {{ descuento.nombre_descuento }}
          </p>
          <div>
            Descuento: {{ Math.floor(descuento.descuento * 100) }}%<br>
            Clientes: {{ customersCount(descuento.id) }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="primary" 
            @click="edit(descuento)"
          >
            Editar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="deleteDiscount(descuento.id)" 
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'
import { ApiAuth } from '@/api_utils/axios-base'
import { mapActions, mapState } from 'vuex'

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      editing: false,
      clientes: [],
      discountCustomers: [],
      customersToDeallocate: [],
      descuentos: [],
      descuento: {
        nombre_descuento: null,
        porcentaje_descuento: 0.0,
        clientes: [],
        clientesConDescuento: [] 
      }
    }
  },
  computed: {
    ...mapState('usuario', ['userData']),
    customerWithoutDiscount() {
      return this.clientes.filter(customer => customer.descuento_distribuidor.length === 0)
    },
    actionText() {
      return this.editing ? 'Actualizar' : 'Agregar'
    }
  },
  methods: {
    ...mapActions('products', ['fetchProducts']),
    async getCustomers() {
      const response = await ApiAuth.get('/personas/api/cliente/')
      const resposeData = await response.data
      this.clientes = resposeData
    },
    async getDiscounts() {
      const response = await ApiAuth.get(`/personas/api/descuentos-distribuidores/?id_distribuidor=${this.userData.distribuidorId}`)
      const responseData = await response.data
      this.descuentos = responseData
    },
    async createDiscount() {
      const response = await ApiAuth.post('/personas/crear-descuento-cliente-distribuidor', {
        nombre_descuento: this.descuento.nombre_descuento,
        id_distribuidor: this.userData.distribuidorId,
        descuento: this.descuento.porcentaje_descuento / 100,
        id_usuario: this.userData.id
      })
      const responseData =  await response.data
      if (responseData.id_descuento) {
        const discountId = responseData.id_descuento
        // Significa que se creo correctamente
        if (this.descuento.clientes) {
          for (const customerId of this.descuento.clientes) {
            await this.assingDiscountToCustomer(customerId, discountId)
          }
        }
      }
      this.clearObject(this.descuento)
    },
    async deleteDiscount(discountId) {
      this.$store.commit('setLoading', true)
      const response = await ApiAuth.delete(`/personas/api/descuentos-distribuidores/${discountId}/?id_distribuidor=${this.userData.distribuidorId}`)
      const responseData = response.data
      setTimeout(() => {
        this.$store.commit('setLoading', false)
        this.fetchProducts()
      }, 500)
    },
    async editDiscount(discountId) {
      const response = await ApiAuth.put(`/personas/api/descuentos-distribuidores/${discountId}/?id_distribuidor=${this.userData.distribuidorId}`, {
        nombre_descuento: this.descuento.nombre_descuento,
        descuento: this.descuento.porcentaje_descuento / 100
      })
      const responseData = await response.data
      // console.log("Descuento editado: ", responseData)
    },
    async assingDiscountToCustomer(customerId, discountId) {
      const response = await ApiAuth.post('/personas/agregar-descuento-cliente',
        {
          id_descuento: discountId,
          id_cliente: customerId
        })
      const responseData = await response.data
      if (responseData.mensaje && responseData.mensaje.includes('Agregado')) {
        console.log('Es descuento se agrego correctamente');
      } else {
        // Se debe mandar a mostrar una alerta
        console.log('No se pudo agregar el descuento ', discountId, ' al cliente ', customerId);
      }
    },
    async deallocateDiscountToCustomer(customerId, discountId) {
      const response = await ApiAuth.post('/personas/quitar-descuento-cliente',
        {
          id_descuento: discountId,
          id_cliente: customerId
        })
      const responseData = await response.data
      if (responseData.mensaje && responseData.mensaje.includes('removido')) {
        console.log('El descuento se desasigno correctamente');
      } else {
        console.log('No se pudo desasignar el decuento ', discountId, ' al cliente ', customerId);
      }
    },
    fullName(customer) {
      const name = customer.datos_personales.primer_nombre
      const secondName = customer.datos_personales.segundo_nombre ? customer.datos_personales.segundo_nombre : ""
      const middleName = customer.datos_personales.apellido_paterno
      const lastName = customer.datos_personales.apellido_materno ? customer.datos_personales.apellido_materno : ""
      return `${name} ${secondName} ${middleName} ${lastName}`
    },
    findCustomersByDiscount(discountId) {
      const customers = this.clientes.filter(customer => {
        return customer.descuento_distribuidor.some(discount => discount.id === discountId)
      })
      return customers
    },
    customersCount(discountId) {
      const customers = this.findCustomersByDiscount(discountId)
      if (customers) {
        return customers.length
      }
      return 0
    },
    edit(discount) {
      this.editing = true
      this.descuento.id = discount.id
      this.descuento.nombre_descuento = discount.nombre_descuento
      this.descuento.porcentaje_descuento = Math.floor(discount.descuento * 100)
      const customersId = []
      const customers = this.findCustomersByDiscount(this.descuento.id)
      if (customers) {
        this.discountCustomers = customers
        customers.forEach(customer => {
          customersId.push(customer.id)
        });
      }
      this.descuento.clientesConDescuento = customersId
    },
    async actionButton() {
      this.$store.commit('setLoading', true)
      if (this.editing) {
        await this.editDiscount(this.descuento.id)
        if (this.descuento.clientes) {
          for (const customerId of this.descuento.clientes) {
            await this.assingDiscountToCustomer(customerId, this.descuento.id)
          }
        }
        if (this.customersToDeallocate.length) {
          for (const customerId of this.customersToDeallocate) {
            await this.deallocateDiscountToCustomer(customerId, this.descuento.id)
            this.customersToDeallocate = []
          }
        }
        this.editing = false
        this.clearObject(this.descuento)
      } else {
        await this.createDiscount()
      }
      setTimeout(() => {
        this.$store.commit('setLoading', false)
        this.fetchProducts()
      }, 500)
    },
    clearObject(object) {
      Object.keys(object).forEach(key => object[key] = null)
    },
    reset() {
      this.$refs.form.reset()
      this.editing = false
    },
    onChange(item) {
      this.customersToDeallocate = []
      const cambiado = new Set(item)
      const completo = new Set(this.discountCustomers)
      const faltante = [...completo].filter(x => !cambiado.has(x.id))
      if (faltante.length) {
        faltante.forEach(f => {
          this.customersToDeallocate.push(f.id)
        })
      }
    }
  },
  created() {
    this.getCustomers()
    this.getDiscounts()
  }
}
</script>

<style>

</style>